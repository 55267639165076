*{
  text-decoration: none !important;
  -ms-overflow-style: none;
}
*::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.buttonWrapper{
  display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    padding: 20px;
    margin-top: 10%;
}

.hide {
  display: none;
}
.stickyCol th{
  position: sticky;
  top: 0px;
}
.stickyCol td:first-child,.stickyCol th:first-child{
  position: sticky;
  left: 0px;
  z-index: 100;
 }
 .stickyCol th:first-child{
  z-index: 101;
 }
 .stickyCol td{
  background-color: white;
 }