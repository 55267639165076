
  
  #material-tabs {
    display: flex;
    overflow: auto;
    position: relative;
    /* display: block; */
    padding:0;
    border-bottom: 1px solid #e0e0e0;
  }
  
  #material-tabs>a,#material-tabs>span {
    position: relative;
   display:inline-block;
    text-decoration: none;
    padding: 22px;
    font-size: 14px;
    font-weight: 600;
    color: #424f5a;
    text-align: center;
  
  }
  
  #material-tabs>a.active {
    font-weight: 700;
    outline:none;
    border-bottom: 3px solid #458CFF;
  }
  
  #material-tabs>a:not(.active):hover {
    background-color: inherit;
    color: #7c848a;
  }
  
  @media only screen and (max-width: 520px) {
    .nav-tabs#material-tabs>li>a {
        font-size: 11px;
    }
  }  
  #tab3-tab.active {
    color:#28a745 ;
    border-bottom: 3px solid #28a745 !important;
  }
  #tab3-tab{
    color:#28a745 !important ;
  }
  #tab4-tab{
    color:#dc3545 !important ;
  }
  #tab4-tab.active {
    color:#dc3545 ;
    border-bottom: 3px solid #dc3545 !important;
  }
  #tab2-tab.active {
    border-bottom: 3px solid #343a40 !important;
  }
  
  #tab2-tab.active ~ span.yellow-bar {
    left:7em;
    width: 8.5em;
  }
  
  #tab3-tab.active ~ span.yellow-bar {
    left: 15.5em;
    width: 9em;
  }
  
  #tab4-tab.active ~ span.yellow-bar {
    left:25em;
    width: 6em;
  }
  .tab-content{
  overflow-x: auto;
  }
  .alert{
    margin-bottom: 0px;
  }
  .bulkUploadTable th{
    font-size: 0.8em;
    white-space: nowrap;
  }
  td{
      text-align: center !important;
      vertical-align: middle!important;
      border-radius: 0px !important;
  }
  th{
      text-align: center;
      border-radius: 0px !important;
      color: grey;
  } 
  table{
      border-spacing: 0px !important;
      text-transform: none !important;
  }
  .retryTd{
    max-width: 100% !important;
    display: flex;
    justify-content: center;
  }